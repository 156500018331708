import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import './LoginLogs.css';
import { LOGGER } from '../../../services/apiEndpoints';
import { privateGet } from '../../../services/privateRequest';
import Spinner from '../../shared/Spinner/Spinner';
import { getLoggedInUserDetails } from '../../../utils/utils';
import moment from 'moment';

const loggedInUser = getLoggedInUserDetails();
const firstName = loggedInUser?.name?.split(' ')[0];

const LoginLogs = ({ searchValue }) => {
  const [allPosts, setAllPosts] = useState([]);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [skip, setSkip] = useState(0);
  const [initialLoadItems, setInitialLoadItems] = useState(10);
  const [addMore, setAddMore] = useState(false);
  const lastItemRef = useRef(null);

  const handlePostTime = (time) => {
    return moment(time).format('MMMM Do YYYY, h:mm:ss a');
  };

  const fetchLoggerData = async () => {
    setIsPostLoading(true);
    try {
      const resp = await privateGet(`${LOGGER}?skip=${skip}&limit=${initialLoadItems}`);
      if (resp?.data?.data) {
        const finalData = resp.data.data;
        setAllPosts((prevPosts) => [...prevPosts, ...finalData]);
        setIsDataEmpty(finalData.length === 0);
      } else {
        setIsDataEmpty(true);
      }
    } catch (error) {
      console.error('Error fetching logger data:', error);
    } finally {
      setIsPostLoading(false);
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > document.documentElement.scrollHeight - 700) {
      setAddMore(true);
    } else {
      setAddMore(false);
    }
  };

  useEffect(() => {
    fetchLoggerData();
  }, [skip]); 

  useEffect(() => {
    if (addMore && !isDataEmpty) {
      setSkip((prevSkip) => prevSkip + initialLoadItems);
    }
  }, [addMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
     <p
          className="groups-heading"
        >
          <Typography variant="h5" component="h5"><b>Logs</b>  </Typography>
    </p>
      <div className='main-feed'>
        <Box
          sx={{
            borderRadius: '6px',
            boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.2)',
            fontSize: '.6rem',
            backgroundColor: 'white',
            width: 'calc(100vw - 438px)',
          }}
        >
          <TabContext value="1">
            <TabPanel value="1" sx={{ padding: '.75rem' }}></TabPanel>
          </TabContext>

          <div>
            <Stack spacing={2}>
              <div className='all-posts-cards'>
                {isDataEmpty ? (
                  <p className='no-posts-message'>No Logs</p>
                ) : (
                  allPosts?.map((item, index) => (
                    <Box
                      key={item._id}
                      className='render-post'
                      ref={index === allPosts.length - 1 ? lastItemRef : null}
                    >
                      <Box className='post-profile'>
                        <Grid container spacing={2}>
                          <Grid item>
                            <div className='post-profile-avatar'>
                              {item?.userId?.name
                                ?.split(' ')
                                .map((i) => i[0]?.toUpperCase())
                                .join('') || 'U'}
                            </div>
                          </Grid>
                          <Grid item>
                            <Box className='profile-details' sx={{ paddingBottom: '0.15rem', userSelect: 'none' }}>
                              {`${item?.userId?.name}`}
                            </Box>
                            <Box className='update-details' sx={{ paddingBottom: '0.15rem', userSelect: 'none' }}>
                              {`${item?.userId?.email}`}
                            </Box>
                            <Box className='update-details' sx={{ userSelect: 'none' }}>
                              {handlePostTime(item.createdAt)}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className='line-container'>
                        <p></p>
                      </Box>
                    </Box>
                  ))
                )}
              </div>
            </Stack>

            {isPostLoading && (
              <div className='align-spinner'>
                <Spinner />
              </div>
            )}
          </div>
        </Box>
      </div>
    </>
  );
};

export default LoginLogs;
