import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { CLIENTS, ROLE } from '../../../services/apiEndpoints';
import { privateDelete, privateGet } from '../../../services/privateRequest';
import { Button, Typography } from '@mui/material';
import "./Client.css"
import AddClient from './AddClient';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { Box } from '@mui/system';
import { getLoggedInUserDetails, getUserRole } from '../../../utils/utils';
import EditIcon from '@mui/icons-material/Edit';

const sample = [
  ['Frozen yoghurt', 159, 6.0, 24, 4.0],
  ['Ice cream sandwich', 237, 9.0, 37, 4.3],
  ['Eclair', 262, 16.0, 24, 6.0],
  ['Cupcake', 305, 3.7, 67, 4.3],
  ['Gingerbread', 356, 16.0, 49, 3.9],
];

function createData(id, dessert, calories, fat, carbs, protein) {
  return { id, dessert, calories, fat, carbs, protein };
}

const getColumns = (userRole) => {
  const baseColumns = [
    {
      width: 120,
      label: 'Name',
      dataKey: 'clientName',
    },
  ];


  if (userRole === 'admin') {
    baseColumns.push(
      {
        width: 80,
        label: 'Created By',
        dataKey: 'createdBy.name',
        numeric: true,
      },
      {
        width: 140,
        label: 'Created At',
        dataKey: 'createdAt',
        numeric: true,
      },
      {
        width: 120,
        label: 'Action',
        dataKey: 'Action',
        numeric: true,
      }
      
    );
  }

  return baseColumns;
};


const handleDeleteClient = async (clientId, setClientsData) => {
  try {
    await privateDelete(CLIENTS, clientId);
    setClientsData((prevClients) =>
      prevClients.filter((client) => client._id !== clientId)
    );
    toast.success("Client Deleted");
  } catch (error) {
    console.error("Error deleting client:", error);
    toast.error("Failed to deleting client");
  }
};

const rows = Array.from({ length: 200 }, (_, index) => {
  const randomSelection = sample[Math.floor(Math.random() * sample.length)];
  return createData(index, ...randomSelection);
});

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent(userRole) {
  const columns = getColumns(userRole);
  return (
    <TableRow>
      {columns?.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width, fontWeight: 'bold' }}
          sx={{ backgroundColor: 'background.paper' }}
        >
          {column?.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row, setClientsData, userRole, handleEdit) {
  const columns = getColumns(userRole);
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          align={column.numeric || false ? 'right' : 'left'}
        >
          {column.dataKey === 'Action' ? (
            <div>
              <EditIcon
                style={{ cursor: 'pointer', marginRight: '6px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(e, row?._id)
                }}
              />
              <DeleteIcon
                style={{ cursor: 'pointer' }}
                onClick={() => handleDeleteClient(row._id, setClientsData)}
              />
            </div>
          ) : column.dataKey === 'createdBy.name' ? (
            row.createdBy?.name
          ) :column.dataKey === 'createdAt' ? (
            new Date(row?.createdAt).toLocaleString()
          ): (
            row[column.dataKey]
          )}
        </TableCell>
      ))}
    </React.Fragment>
  );
}



export default function Client() {
  const [clientsData, setClientsData] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [userRole, setUserRole] = React.useState("");
  const [flag, setFlag] = React.useState(false)
  const [singleClient, setSingleClient] = React.useState([])
  const [clientId, setClientId] = React.useState();
  
 
  const loggedInUser = getUserRole('role')
  const handleEdit = (e, clientId) => {
    // openAddModal();
    e.stopPropagation();
    openEditModal(true);
    const clientToEdit = clientsData.find(client => client._id === clientId);
    setSingleClient(clientToEdit);
    setClientId(clientId)

  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const openEditModal = () => {
    setEditModalOpen(true)

  };

  const closeEditModal = () => {
    setEditModalOpen(false)

  };

  const handleAddClient = (newClient) => {
    setClientsData((prevClients) => [...prevClients, newClient]);
  };

  const handleEditClient = async (newClient) => {
    setClientsData((prevClients) => [...prevClients, newClient]);
  };

  React.useEffect(() => {
    setUserRole(getUserRole('role'))
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await privateGet(CLIENTS);
        if (res) {
          setClientsData(res.data.data);
        }
      } catch (error) {
        console.error("Error fetching clients data:", error);
      }
    };

    fetchData();
  }, [flag]);


  return (

    <div className="groups-container-div" >
      <p
        className="groups-heading"
      >
       <Typography variant="h5" component="h5"> <b>Clients</b> </Typography>
        { loggedInUser === "admin" && (
        <Button
          sx={{
            fontSize: ".65rem",
            height: '30px',
            cursor: 'pointer'
          }}
          variant='contained'
          onClick={handleOpenModal}
        >
          Add Client
        </Button>
        )}
      </p>


      <Paper style={{ height: 440, width: '89%' }}>
        <TableVirtuoso
          data={clientsData}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() => fixedHeaderContent(userRole)}
          itemContent={(index, row) => rowContent(index, row, setClientsData, userRole, handleEdit)}
        />
      </Paper>
      <AddClient
        open={open}
        onClose={handleCloseModal}
        clientData={clientsData}
        onAddClient={handleAddClient}
        setFlag={setFlag}
      />
      <AddClient
        open={editModalOpen}
        onClose={closeEditModal}
        editClientData={singleClient}
        handleEditClient={handleEditClient}
        edit={true}
        setFlag={setFlag} 
        flag={flag}
        clientId={clientId}
      />
    </div>
  );
}